<template>
  <GedViewer v-if="folderId > 0" :parentFolder="folderId" :origine="'global'" />
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import {
  BTable,
  BFormGroup,
  BFormRadio,
  BFormCheckbox,
  BFormRadioGroup,
  BCardBody,
  BButtonToolbar,
  BButtonGroup,
  BButton,
} from "bootstrap-vue";

var dayjs = require("dayjs");
var quarterOfYear = require("dayjs/plugin/quarterOfYear");
var utc = require("dayjs/plugin/utc");
var timezone = require("dayjs/plugin/timezone");
var advanced = require("dayjs/plugin/advancedFormat");
require("dayjs/locale/fr");
dayjs.locale("fr");
dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.tz.setDefault("Europe/Paris");
dayjs.extend(advanced);
dayjs.extend(quarterOfYear);

import GedViewer from "@/components/ged/GedViewer";

export default {
  components: {
    BTable,
    BFormGroup,
    BCardBody,
    BFormRadio,
    BFormCheckbox,
    BFormRadioGroup,
    BButtonToolbar,
    BButtonGroup,
    BButton,
    GedViewer,
  },
  data() {
    return {
      folderId: 0,
    };
  },
  watch: {
    async $route(to, from) {
      this.$destroy();
    },
  },
  async mounted() {
    this.folderId = await this.getFolderByName();
  },

  methods: {
    ...mapActions(["getFolderByName"]),
  },
};
</script>

<style lang="scss">
.con-vs-tabs .con-slot-tabs {
  height: 100%;
  .vs-tabs--content {
    padding: 0px;
    height: 100%;
    > div {
      padding: 0px;
      height: 100%;
    }
  }
}
</style>
